import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form } from 'react-bootstrap';
import Header2 from "../../../../components/Header2/header2";
import Footer from "../../../../components/property-footer";
import GetAreGuide from "../../../../components/get-area-guide"
import LetFilter from "../../../../components/letFilter"

// 
import $ from "jquery";
import qs from "qs"
import {
  parseSearchUrl,
  propertyH1
} from "../../../../components/property-search-map/newdevelopmentutils";
import algoliasearch from "algoliasearch/lite"
import {
    connectStats,
    SortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    connectSortBy,
    ToggleRefinement
} from "react-instantsearch-dom"
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
  CustomMarker
} from 'react-instantsearch-dom-maps'
import Cookies from 'universal-cookie'
import { navigate } from "@reach/router"
import NewHomesSeoResults from "../../../../components/property-search-map/newhomeseoResults"
import { ShowProcessedImage } from '../../../../components/common/ggfx-client/module/components/show-image';
import "../../assets/styles/_index.scss"
// 

import { Search, Exclamation, MapPointer, Equalto, Filter, Notification, Bedroom, Bathroom, Reception, Kitchen } from '../../../../components/icon';
import imageConfig from "../../../../../static/images/config.json"

const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

const searchClient = algoliasearch(
    `${process.env.GATSBY_ALGOLIA_APP_ID}`,
    `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
  )

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

// Property resutls loop
const InfiniteHits = ({
    hits,
    hasPrevious,
    refinePrevious,
    hasMore,
    refineNext,
    location,
}) => (
    <>
        <Row>
            <Col lg={12} className="text-center">
            {(hits.length > 0 && hasPrevious) && (
                <button
                    id="myprevbut"
                    onClick={refinePrevious}
                    disabled={!hasPrevious}
                    className="text-uppercase d-none property_show_more_btn"
                >
                    Show prev
                </button>
            )}
            </Col>
        </Row>

        {
            hits.map(hit => {

              // property details url structure
              let uriStr = "";

              if(hit.searchType === "lettings" ) {
                uriStr = `new-development-to-rent/`
              }
              // property details url structure

              //property description
              let text_truncate = function(str, length, ending) {
                if(str){
                    str = str.replace(/<[^>]*>/g, "");
                }
                if (length == null) {
                  length = 180;
                }
                if (ending == null) {
                  ending = '...';
                }
                if (str.length > length) {
                  return str.substring(0, length - ending.length) + ending;
                } else {
                  return str;
                }
              };
              if(hit.status == 'Let' || hit.status =='Let Agreed') {
                var prostatus = hit.status
              } else {
                var prostatus = ''
              }
              // property description

                return (
                    <>
                        <div id={`myRentProp${hit.objectID}`} className="result-list d-xl-flex">
                            <figure className="result-img">
                                <Link onClick={() => mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 })}
                                to={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                                state={{ referrer: location.pathname }}>
                                    <ShowProcessedImage images={hit.images[0]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults.sizes} />
                                </Link>
                                {prostatus &&
                                  <div className='propertyStatus'>{hit.status}</div>
                                }
                            </figure>
                            <div className="result-desc">
                                <h2>{hit.display_address}</h2>
                                <span className="price-info">£{hit.price.toLocaleString()}</span>
                                <span className="room-info">{hit.title}</span>
                                <p className="d-none d-md-block">{text_truncate(hit.description)}</p>
                                <div className="btn-wrap d-flex align-items-center justify-content-between">
                                    <Link onClick={() => mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 })}
                                    to={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                                    state={{ referrer: location.pathname }} className="btn-view">View Details</Link>
                                <GetAreGuide postcode={hit.postcode.split(" ")[0]} />
                                </div>
                            </div>
                        </div>
                    </>
                )
            })
        }

        <Row>
            <Col lg={12} className="text-center">
                {(hits.length > 0 && hasMore) && (
                <button
                    id="mynextbut"
                    onClick={refineNext}
                    disabled={!hasMore}
                    className="btn btn-default"
                >
                    Show more
                </button>
                )}
            </Col>
        </Row>
    </>
)

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)
// Property resutls loop

// Property Search
const PropertySearchBox = connectSearchBox(({ currentRefinement, refine }) => {
    return (
        <Form className="filter-form" action="javascript:void(0)">
            <Form.Group controlId="formBasicEmail">
                <Form.Control type="text" name="search" onChange={event => refine(event.currentTarget.value)} placeholder="Street, area or postcode" />
            </Form.Group>
            <button className="btn-search" type="submit">
                <Search />
            </button>
        </Form>
    )
})
// Property Search

// Property Min price
const priceminRange = [
    {
        value : "50",
        label : "£50"
    },
    {
        value : "100",
        label : "£100"
    },
    {
        value : "200",
        label : "£200"
    },
    {
        value : "300",
        label : "£300"
    },
    {
        value : "400",
        label : "£400"
    },
    {
        value : "500",
        label : "£500"
    },
    {
        value : "600",
        label : "£600"
    },
    {
        value : "700",
        label : "£700"
    },
    {
        value : "800",
        label : "£800"
    },
    {
        value : "900",
        label : "£900"
    },
    {
        value : "1000",
        label : "£1,000"
    },
    {
        value : "1250",
        label : "£1,250"
    },
    {
        value : "1500",
        label : "£1,500"
    },
    {
        value : "1750",
        label : "£1,750"
    },
    {
        value : "2000",
        label : "£2,000"
    },
    {
        value : "2250",
        label : "£2,250"
    },
    {
        value : "2500",
        label : "£2,500"
    },
    {
        value : "2750",
        label : "£2,750"
    },
    {
        value : "3000",
        label : "£3000"
    },
    {
        value : "3500",
        label : "£3,500"
    },
    {
        value : "4000",
        label : "£4,000"
    },
    {
        value : "5000",
        label : "£5,000"
    },
    {
        value : "7500",
        label : "£7,500"
    },
    {
        value : "10000",
        label : "£10,000"
    }
]

const minpriceSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {
    let minPriceRange = priceminRange;
    if (currentRefinement.max !== undefined) {
      minPriceRange = priceminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
    }
    return (
      <select
        name="minprice"
        value={currentRefinement.min || ""}
        onChange={event => {
          minpriceval = event.currentTarget.value
          if (event.currentTarget.value === "") {
            if (maxpriceval) {
              refine({
                max: maxpriceval,
              })
            } else refine(event.currentTarget.value)
          } else {
            refine({
              min: event.currentTarget.value,
              max: maxpriceval,
            })
          }
          $('html, body').animate({
            scrollTop: $(".result-head").offset().top - 250
          }, 1000);
        }}
      >
        <option value="">Min Price</option>
        {minPriceRange.map((option, key) => {
          return (
            <option value={option.value} key={key}>
              {option.label}
            </option>
          )
        })}
      </select>
    )
}

const CustomminpriceSelectbox = connectRange(minpriceSelectbox)
// Property Min price

// Property Max price
const pricemaxRange = [
    {
        value : "50",
        label : "£50"
    },
    {
        value : "100",
        label : "£100"
    },
    {
        value : "200",
        label : "£200"
    },
    {
        value : "300",
        label : "£300"
    },
    {
        value : "400",
        label : "£400"
    },
    {
        value : "500",
        label : "£500"
    },
    {
        value : "600",
        label : "£600"
    },
    {
        value : "700",
        label : "£700"
    },
    {
        value : "800",
        label : "£800"
    },
    {
        value : "900",
        label : "£900"
    },
    {
        value : "1000",
        label : "£1,000"
    },
    {
        value : "1250",
        label : "£1,250"
    },
    {
        value : "1500",
        label : "£1,500"
    },
    {
        value : "1750",
        label : "£1,750"
    },
    {
        value : "2000",
        label : "£2,000"
    },
    {
        value : "2250",
        label : "£2,250"
    },
    {
        value : "2500",
        label : "£2,500"
    },
    {
        value : "2750",
        label : "£2,750"
    },
    {
        value : "3000",
        label : "£3000"
    },
    {
        value : "3500",
        label : "£3,500"
    },
    {
        value : "4000",
        label : "£4,000"
    },
    {
        value : "5000",
        label : "£5,000"
    },
    {
        value : "7500",
        label : "£7,500"
    },
    {
        value : "10000",
        label : "£10,000"
    }
]

const maxpriceSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {
    let maxPriceRange = pricemaxRange;
    let maxpricedisable = ''
    if (currentRefinement.min !== undefined) {
      maxPriceRange = pricemaxRange.filter((x) => parseInt(x.value) > parseInt(currentRefinement.min))
    }
    if (currentRefinement.min == "10000000") {
      maxpricedisable = 'disabled'
    }
    return (
      <select
        name="maxprice"
        disabled={maxpricedisable}
        value={currentRefinement.max || ""}
        onChange={event => {
          maxpriceval = event.currentTarget.value
          if (event.currentTarget.value === "") {
            if (minpriceval) {
              refine({
                min: minpriceval,
              })
            } else refine(event.currentTarget.value)
          } else {
            refine({
              min: minpriceval,
              max: event.currentTarget.value,
            })
          }
          $('html, body').animate({
            scrollTop: $(".result-head").offset().top - 250
          }, 1000);
        }}
      >
        <option value="">Max Price</option>
        {maxPriceRange.map((option, key) => {
          return (
            <option value={option.value} key={key}>
              {option.label}
            </option>
          )
        })}
      </select>
    )
}
  
const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
// Property Max price

// Property Bedrooms
const bedroomSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {
    let bedroomvalselect = ""
    if (currentRefinement.max !== undefined) {
      bedroomvalselect = 0
    } else if (currentRefinement.min !== undefined) {
      bedroomvalselect = currentRefinement.min
    }
    return (
      <>
        <select
          name="bedroom"
          value={bedroomvalselect}
          onChange={event => {
            if (event.currentTarget.value === "") {
              refine(event.currentTarget.value)
            } else if (event.currentTarget.value === "0") {
              refine({
                max: event.currentTarget.value,
              })
            } else {
              refine({
                min: event.currentTarget.value,
              })
            }
            $('html, body').animate({
                scrollTop: $(".result-head").offset().top - 250
            }, 1000);
          }}
        >
        <option value="">Bedrooms</option>
        <option value="1" key="1">1 +</option>
        <option value="2" key="2">2 +</option>
        <option value="3" key="3">3 +</option>
        <option value="4" key="4">4 +</option>
        <option value="5" key="5">5 +</option>
      </select>
      </>
    )
}
  
const CustombedroomSelectbox = connectRange(bedroomSelectbox)
// Property Bedrooms

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine }) => (
    <select
      name="building"
      value={currentRefinement || ""}
      onChange={event => refine(event.currentTarget.value)}
    >
      <option value="">Property Type</option>
      <option value="apartment" key="apartment">Apartments</option>
      <option value="house" key="house">Houses</option>
    </select>
)
  
const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Property Sortby
const SortByPrice = ({ items, currentRefinement, refine }) => (
    <>
      {items.map((item, key) => {
        return (
            <button key={key} className={item.class+" btn-price"} onClick={event => {
                event.preventDefault();
                refine(item.value);
            }}>
                <Filter />
                {item.label} <span className="d-none d-md-inline">First</span>
            </button>
        )
      })}
    </>
)
  
const CustomSortBy = connectSortBy(SortByPrice);
// Property Sortby

// Maps
const MapResults = (() => {
    return (
       <>
        <Configure 
          // hitsPerPage={10}
        />
        <div id="map" className="property_search_map">
          <GoogleMapsLoader apiKey="AIzaSyBhjHIeuTAPYPHBJZUrLCkyeHnpkan9w4c">
            {google => (
                <GeoSearch 
                    google={google}
                    enableRefineOnMapMove={false}
                    initialZoom={9}
                  >
                    {({ hits }) => (
                        <div>
                            {hits.map(hit => (
                                <>
                                    <CustomMarker key={hit.objectID} position={hit._geoloc} hit={hit}
                                        className={"icon-property_map_marker map-marker-"+ hit.objectID}
                                        onClick={({ event, marker }) => {
                                            // console.log("map event ---",event);
                                            // console.log("map marker ---",marker);
                                            $('html, body').animate({
                                                scrollTop: $("#myRentProp"+hit.objectID).offset().top - 200
                                            }, 2000);
        
                                            $(".map-marker-"+hit.objectID).addClass("active").siblings().removeClass("active")
                                            $("#myRentProp"+hit.objectID).addClass("active").siblings().removeClass("active")
                                        }}
                                    />
                                </>
                            ))}
                        </div>
                    )}
                </GeoSearch>
            )}
          </GoogleMapsLoader>
        </div>
      </>
    )
})
// Maps

// No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
    return (
        <>
        {nbHits === 0 &&
            <Row>
                <Col lg={12} className="text-center mb-5">
                <div>Unfortunately, we do not currently have any properties that match your search criteria.</div>
                </Col>
            </Row>
        }
        </>
    )
}

const CustomNoStats = connectStats(NoStats)
// No Results
  
// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Create URL
export const createURL = (props, state) => {
    let myarea = state.menu

    const isDefaultRoute = 
    !state.query &&
    !state.connectSortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.bedroom

    if (isDefaultRoute) {
        return ""
    }

    var areaPath = "in-manchester/"
    var bedroomPath = ""
    var pricePath = ""
    var pagePath = ""
    var propertypath = ""
    var sortPath = ""

    if (state.query) {
        if (state.query) {
          state.query = state.query.split(" ").join("-").toLowerCase();
          areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
        }
        areaPath = areaPath.toLowerCase()
    }

    const queryParameters = {}

    // Property path
    if (state.menu) {
        if (myarea["building"]) {
          propertypath = "type-" + myarea["building"] + "/"
        }
    }
    // Property path

    // Bedrooms & Price range path
    if (state.range) {
        // Bedrooms
        if (state.range.hasOwnProperty('bedroom') && state.range.bedroom.max === 0) {
        bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
        }
        if (state.range.bedroom) {
            if (state.range.bedroom.min) {
                bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
            }
        }

        // Price
        if (state.range.price) {
            if (state.range.price.min && state.range.price.max) {
                pricePath =
                "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
            } else if (state.range.price.max) {
                pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
            } else if (state.range.price.min) {
                pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
            }
        }
    }
    // Bedrooms & Price range path

    // Sort path
    if(state.sortBy) {
      if( state.sortBy === index_name+"_price_asc" ) {
        sortPath = "sortby-price-asc/";
      }
      if( state.sortBy === index_name ) {
        sortPath = "";
      }
    }
    // Sort path

    // Page path
    if (state.page && state.page !== 1) {
        pagePath = `page-${state.page}/`
    }
    // Page path

    const queryString = qs.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: "repeat",
    })

    let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`
  
    if ("/" === myUrl.substr(myUrl.length - 1))
      myUrl = myUrl.substr(0, myUrl.length - 1)
    
    return `/property-map/new-developments/to-rent/${myUrl}/`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
    if (location) {
        var query = ""
        let pathUri_main = location.pathname.split("/property-map/new-developments/to-rent")

        var areaVal = ""
        var bedVal = 11
        var minpriceVal = ""
        var maxpriceVal = ""
        var pageVal = ""
        var propertyval = ""
        var sortVal = ""
        var sortVal_filt = ""

        if (pathUri_main[1]) {
            // following could be regexp
            let pathUri = pathUri_main[1].split("/")

            //lets loop
            for (let vi = 1; vi <= pathUri.length; vi++) {
                // check for area
                if (typeof pathUri[vi] === "undefined") {
                    continue
                }

                // Property type
                if (pathUri[vi].indexOf("type-") >= 0) {
                    propertyval = pathUri[vi].replace("type-", "")
                }

                // Area
                if (pathUri[vi].indexOf("in-") >= 0) {
                    areaVal = pathUri[vi].replace("in-", "")
                }

                // Price
                if (
                    pathUri[vi].indexOf("between-") >= 0 ||
                    pathUri[vi].indexOf("over-") >= 0 ||
                    pathUri[vi].indexOf("under-") >= 0
                ) {
                    let priceFilt1 = pathUri[vi].split("over-")
                    if (priceFilt1[1]) {
                    minpriceVal = priceFilt1[1]
                    }
                    let priceFilt2 = pathUri[vi].split("under-")
                    if (priceFilt2[1]) {
                    maxpriceVal = priceFilt2[1]
                    }
                    let priceFilt3 = pathUri[vi].split("between-")
                    if (priceFilt3[1]) {
                    let priceFilt4 = priceFilt3[1].split("-and-")
                    minpriceVal = priceFilt4[0]
                    maxpriceVal = priceFilt4[1]
                    }
                }

                // Bedrooms
                if (pathUri[vi].indexOf("-and-more-") >= 0) {
                    bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
                }

                if (pathUri[vi].indexOf("studio") >= 0) {
                    // its studio bedroom
                    bedVal = 0
                }

                // Sort by
                if (pathUri[vi].indexOf("sortby-") >= 0) {
                  sortVal_filt = pathUri[vi].replace("sortby-", "")

                  if(sortVal_filt === "price-asc") {
                    sortVal = index_name+"_price_asc"
                  }

                  if(sortVal_filt === "price-desc") {
                    sortVal = index_name+"_price_desc"
                  }
                }

                // Page
                if (pathUri[vi].indexOf("page") >= 0) {
                    pageVal = pathUri[vi].replace("page-", "")
                }
            }
        }
    }

    // Property type
    if (propertyval) {
        query += `&menu[building]=` + propertyval
    }

    // Area
    if (areaVal) {
        areaVal = areaVal.toLowerCase();
        if (areaVal == "manchester") query += `&query=`
        else query += `&query=` + areaVal
    }

    // Price
    if (minpriceVal) {
        query += `&range[price][min]=` + minpriceVal
    }

    if (maxpriceVal) {
        query += `&range[price][max]=` + maxpriceVal
    }

    // Bedrooms
    if (bedVal == 0) {
        query += `&range[bedroom][min]=` + 0
    } else if (bedVal < 11) {
        query += `&range[bedroom][min]=` + bedVal
    }

    // Page
    if (pageVal) {
        query += `&page=${pageVal}`
    }

    // Sort by
    if (sortVal) {
      query += `&sortBy=` + sortVal
    }

    return qs.parse(query)
}
// URL to searchstate

class PropertySearchMapNewHomesRentals extends React.Component {

    // 
    state = {
        searchState: urlToSearchState(this.props.location),
        includeLetinside: true
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
          this.setState({ searchState: urlToSearchState(this.props.location) })
        }
    }

    onSearchStateChange = searchState => {
        clearTimeout(this.debouncedSetState)
          this.debouncedSetState = setTimeout(() => {
            navigate(searchStateToUrl(this.props, searchState), searchState)
          }, updateAfter)
        this.setState({ searchState })
    }

    onSearchStateChange2 = (searchState) => {      
        this.setState({ searchState })
    }
    // 

    componentDidMount() {
        if ( (window.location.href).indexOf('property-map/new-developments/to-rent') ) {

            // Infinite properties scroll
            $(window).scroll(function() {
    
            //   if ( $("#mynextbut").length ) {
            //     var top_of_element = $("#mynextbut").offset().top;
            //     var bottom_of_element = $("#mynextbut").offset().top + $("#mynextbut").outerHeight();
            //     var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            //     var top_of_screen = $(window).scrollTop();
            
            //     if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
            //       $("#mynextbut").trigger( "click" );
            //        //window.scrollTo({ top: top_of_element-100, behavior: 'smooth' });
            //         // the element is visible, do something
            //     }
            //   }
              
              if ( $("#myprevbut").length ) {
                var top_of_element = $("#myprevbut").offset().top;
                var bottom_of_element = $("#myprevbut").offset().top + $("#myprevbut").outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();
            
                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                  $("#myprevbut").trigger( "click" );
                }
              }
    
            });
            // Infinite properties scroll
        }

        // Sortby option price
        $(".property_sortby_asc").click(function() {
          $(".property_sortby_asc").addClass("active");
          $(".property_sortby_desc").removeClass("active");
        })

        $(".property_sortby_desc").click(function() {
          $(".property_sortby_desc").addClass("active");
          $(".property_sortby_asc").removeClass("active");
        })
        // Sortby option price

        // Info button seo text
        $(".icon-info").click(function() {
            $(".search_text").toggleClass("active")
        })
        // Info button seo text

        // load on top
        $(document).ready(function(){
            $("html, body").scrollTop(0);
        });

        // $( ".filtersearchsoldlet" ).insertAfter( ".defaultsoldfilter" );
        // load on top
    }

    // Sales & Rent dropdown
    onChangeSellRent = (e) => {
        navigate(`/${e.currentTarget.value}/`)
    }
    // Sales & Rent dropdown
    includeLetclick = e => {
        this.setState({
          includeLetinside: !this.state.includeLetinside
        })
      }
    render () {
      const searchParams = parseSearchUrl("lettings", this.props.location.pathname);
      const h1 = propertyH1(searchParams);
      const areaName = searchParams.areas;
      const pType = searchParams.pType;

      const isPageLoadBg = (typeof this.props.path === "undefined");

      let locType;
      if (areaName === "london") {
        locType = "Battersea, Nine Elms, Clapham"
      } else {
        locType = areaName
      }

      let areaPropertyName;

      if (h1 === "New Homes to rent in London") {
        areaPropertyName = "New Homes to rent in Battersea, Nine Elms, Clapham";
      } else if ( h1 === "Apartments to rent in London" ) {
        areaPropertyName = "Apartments to rent in Battersea, Nine Elms, Clapham";
      } else if ( h1 === "Houses to rent in London" ) {
        areaPropertyName = "Houses to rent in Battersea, Nine Elms, Clapham";
      } else {
        areaPropertyName = h1;
      }

        // Filter button
        const searchFilter = (e) => {
            $(".filter-list").toggleClass("active");
        }
        // Filter button

        // Property list & navigate
        let urlProperty = "/";
        const propertyMap = (e) => {
            urlProperty = "/property-map/new-developments/to-rent/";
            navigate(urlProperty);
        }

        const propertyList = (e) => {
            urlProperty = "/property/new-developments/to-rent/";
            navigate(urlProperty);
        }

        const propertyMapMobile = (e) => {
            $(".result-list-wrap").removeClass("active")
            $(".map-wrap").addClass("active")
            $(".map-marker").removeClass("map-view-present")
            $(".btn-map").removeClass("map-view-active")
        }
    
        const propertyListMobile = (e) => {
            $(".result-list-wrap").addClass("active")
            $(".map-wrap").removeClass("active")
            $(".btn-map").addClass("map-view-active")
            $(".map-marker").addClass("map-view-present")
        }
        // Property list & navigate
        var defaultDepartment = ["new_developments"];
       
        let mydepartment = ''
    if(defaultDepartment){
      if(Array.isArray(defaultDepartment)){
        mydepartment = '(department:"'+(defaultDepartment).join('" OR department:"')+'")'
      } else {
        mydepartment = '(department:"'+(defaultDepartment)+'")'
      }
    }
    
    var defaultStatus = this.state.includeLetinside ? ["To Let", "Let", "Let Agreed"] : ["To Let"];
           
        let mystatus = ''
    if(defaultStatus){
      if(Array.isArray(defaultStatus)){
        mystatus = '(status:"'+(defaultStatus).join('" OR status:"')+'")'
      } else {
        mystatus = '(status:"'+(defaultStatus)+'")'
      }
    }
        return (
            <>
                <div className="sub-wrapper">
                    <Header2 />

                    {!isPageLoadBg &&
                      <NewHomesSeoResults title={h1} searchParams={searchParams} location={this.props.location} />
                    }

                    <div className="content">
                        {/* <div className="filtersearchsoldlet"> */}
                            {/* <LetFilter searchState={this.state.searchState}
                                searchClient={searchClient}
                                indexName={index_name}
                                createURL={createURL}
                                onSearchStateChange={this.onSearchStateChange2}
                            /> */}
                        {/* </div> */}
                        <InstantSearch
                            indexName={index_name}
                            searchClient={searchClient}
                            searchState={this.state.searchState}
                            onSearchStateChange={this.onSearchStateChange}
                            createURL={createURL}
                            routing="true"
                        >

                            <div className="filter-bar">
                                <div className="d-none">
                                    {/* <RefinementList
                                        attribute="searchType"
                                        defaultRefinement={["lettings"]}
                                    />
                                    <RefinementList
                                        attribute="status"
                                        defaultRefinement={["To Let"]}
                                    />
                                    <RefinementList
                                        attribute="department"
                                        defaultRefinement={["new_developments"]}
                                    /> */}
                                </div>
                                <Configure
                            filters={`searchType:lettings AND ${mystatus} AND ${mydepartment}`} 
                                
                            />
                                <Container className="d-lg-flex align-items-lg-center justify-content-lg-between">
                                    <PropertySearchBox />
                                    <div className="filter-list-wrap">
                                        <button onClick={searchFilter} className="filter-btn d-lg-none">filter results</button>
                                        <ul className="filter-list">
                                            <li>
                                                <div className="select-dropdwn2 slct-min-price">
                                                    <select onChange={this.onChangeSellRent}>
                                                        <option value="property-map/new-developments/to-rent/">Lettings</option>
                                                        <option value="property-map/new-developments/for-sale/">Sales</option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="select-dropdwn2 slct-min-price">
                                                    <CustomminpriceSelectbox attribute="price" />
                                                </div>
                                            </li>
                                            <li>
                                                <div className="select-dropdwn2 slct-max-price">
                                                    <CustommaxpriceSelectbox attribute="price" />
                                                </div>
                                            </li>
                                            <li>
                                                <div className="select-dropdwn2 slct-prprty">
                                                    <CustombuildingSelectbox attribute="building" />
                                                </div>
                                            </li>
                                            <li>
                                            <div className="filtersearchsoldlet">
                                                <div className="property-filter_check defaultsoldfilter">
                                                {/* <ToggleRefinement
                                                    attribute="status"
                                                    label="Include Let"
                                                    value={['Let']}
                                                    defaultRefinement={["Let"]}
                                                /> */}
                                                   <div class="ais-ToggleRefinement">
                <label class="ais-ToggleRefinement-label">
                    <input type="checkbox" class="ais-ToggleRefinement-checkbox" value="" id="flexCheckDefault" 
                    checked={this.state.includeLetinside ? "checked" : ""}
                    onChange={this.includeLetclick}
                     />
                    <span class="ais-ToggleRefinement-labelText">Include Let</span>
                </label>
            </div>
            </div>
                                                </div>
                                                <div className="d-none">
                                                {/* <ToggleRefinement
                                                    attribute="status"
                                                    label="Let Agreed"
                                                    value={['Let Agreed']}
                                                    defaultRefinement={['Let Agreed']}
                                                /> */}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </Container>
                            </div>

                            <section className="result-map">
                                <Container>
                                    <Row>
                                        <Col xl="8" className="pr-xl-0">
                                            <div className="result-head">
                                                <div className="breadcrumbs"><nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li class="breadcrumb-item">                      
                                                    <a href="/">Home</a></li>
                                                    <li class="breadcrumb-item"><a>New Developments To Rent</a></li>
                                                    </ol>
                                                    </nav>
                                                </div>
                                                <div className="result-info d-flex align-items-start">
                                                    <h1>{h1} (<CustomStats />)
                                                        <i className="icon-info">
                                                        <Exclamation /></i>
                                                    </h1>
                                                    <ul className="views d-flex align-items-center d-lg-none">
                                                        <li>
                                                            <button className="map-marker" onClick={propertyMapMobile}>
                                                                <MapPointer />
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="btn-map" data-toggle="tooltip" data-placement="top" title="List View" onClick={propertyListMobile}>
                                                                <Equalto />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p className="search_text">
                                                    Discover a wide range of {areaPropertyName} and the surrounding area in London with Orlando Reid. Refine your search using the filters below and for more information about {pType} property in <span className="property_area_captialize">{locType}</span> please contact Orlando Reid Estate Agents.
                                                </p>
                                                <div className="notify-bar d-lg-flex align-items-lg-center justify-content-lg-between">
                                                    <ul className="alert-list d-flex">
                                                        <li className="property_sortby">
                                                            <CustomSortBy 
                                                                items={[
                                                                    { value: index_name+'_price_asc', label: 'Lowest Price', class: 'property_sortby_asc' },
                                                                    { value: index_name, label: 'Highest Price', class: 'property_sortby_desc active' },
                                                                ]}
                                                                defaultRefinement={index_name}
                                                            />
                                                        </li>
                                                        <li>
                                                        <a href="https://orlandoreid.propertyfile.co.uk/" target="_blank">

                                                            <button className="btn-bell">
                                                                <Notification />
                                                    Get Alerts <span className="d-none d-md-inline">for New Properties</span></button>
                                                    </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="views d-none d-lg-flex align-items-lg-center">
                                                        <li>
                                                            <button className="map-marker" onClick={propertyMap}>
                                                                <MapPointer />
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="btn-map" data-toggle="tooltip" data-placement="top" title="List View" onClick={propertyList}>
                                                                <Equalto />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="result-list-wrap">
                                                <CustomInfiniteHits location={this.props.location} />
                                                <CustomNoStats />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                <div className="map-wrap d-none d-xl-flex active">
                                    <MapResults />
                                </div>
                            </section>

                        </InstantSearch>
                    </div>

                    <Footer />
                </div>
            </>
        )
    }
}

export default PropertySearchMapNewHomesRentals